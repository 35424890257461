<template>
  <div
    v-infinite-scroll="handleInfiniteOnLoad"
    class="demo-infinite-container all_main_wrap"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-distance="10"
  >
    <!-- 头部 -->
    <HeaderBox>
      <template #title> 操作日记 </template>
      <template #input>
        <a-range-picker
          :disabled-date="disabledDate"
          v-model="selKey"
          @change="onChange"
        />
        <!-- 选择框固定长度为193px 选择框后缀图标：slot="suffixIcon"-->

        <!-- <a-select @change="handleChange" placeholder="">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="lucy"> 行大运 </a-select-option>
        </a-select> -->
        <!-- <a-input placeholder="请输入关键字" /> -->
        <a-input
          v-model="name"
          placeholder="选择操作人"
          readOnly
          @click="relationFilling"
        />
        <a-button class="all_btn_solid btn" type="primary" @click="search()"
          >搜索</a-button
        >
        <a-button class="all_boder_btn" @click="reset()">重置</a-button>
      </template>
    </HeaderBox>
    <!-- 操作日记 -->

    <!-- 内容 -->
    <a-collapse :activeKey="activeKey" @change="selDate">
      <a-collapse-panel v-for="items in dateList" :key="items.name">
        <template slot="header">
          {{ items.name }}
        </template>
        <div class="box_con">
          <div class="con_item">
            <div v-if="items.children.length" class="con_main">
              <div
                class="main_item"
                v-for="(item, index) in items.children"
                :key="index"
              >
                <div class="item_title">
                  <span class="title_info">{{ item.time }} </span>
                  <span class="title_date"> {{ item.managerName }}</span>
                </div>
                <div>
                  <span class="title1">{{ item.log }}</span>

                  <div class="item_con">{{ item.info }}</div>
                </div>
              </div>
            </div>
            <a-empty v-else />
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <!-- 操作人选择 -->
    <a-modal
      v-model="relation"
      width="641px"
      title="选择关联"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-input class="search-input" placeholder="请输入关键字" />
        <a-button class="search-btn" type="primary">搜索</a-button>
      </div>
      <a-table
        class="table-template"
        :row-selection="{
          columnTitle: '选择',
          selectedRowKeys: selectedRowKey,
          onChange: selectKeys,
        }"
        :rowKey="(item) => item.userId"
        :columns="columns"
        :data-source="tableData"
        :filterMultiple="false"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
        }"
        bordered
        @change="oncPage"
      >
      </a-table>
      <template slot="footer">
        <a-button type="primary" @click="relationOk">确认</a-button>
        <a-button @click="relationCanael">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import reqwest from "reqwest";
import infiniteScroll from "vue-infinite-scroll";
import moment from "moment";
const fakeDataUrl =
  "https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo";

const columns = [
  {
    title: "用户id",
    align: "center",
    dataIndex: "userId",
  },
  {
    title: "昵称",
    align: "center",
    dataIndex: "name",
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      list: [
        {
          date: "19:23",
          title: "用户名",
          info: "审核xx的报名信息",
        },
        {
          date: "19:35",
          title: "用户名",
          info: "xx的报名信息审核成功",
        },
        {
          date: "19:23",
          title: "用户名",
          info: "审核xx的报名信息",
        },
        {
          date: "19:23",
          title: "用户名",
          info: "审核xx的报名信息",
        },
      ],
      data: [],
      loading: false,
      busy: false,
      selKey: [],
      activeKey: [],
      dateList: [],

      userinfo: null,
      userId: null,

      strdate: null,
      // 暂存内容
      righttime: null,
      days: null,

      //键值密钥
      tableData: [],
      total: "",
      columns,
      selectedRowKey: [],
      ctotal: 0,
      cpageNumber: 1, //页码
      cpageSize: 10, // 页数
      categorytype: 1,
      relation: false,
      name: "",
      // 判断状态
      ifdate: true,
    };
  },
  // 事件处理器
  methods: {
    moment,
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    disabledRangeTime(_, type) {
      if (type === "start") {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
    // 搜索
    search() {
      if (!this.righttime) {
        this.$message.warning("请选择时间");
        return;
      }
      this.dateList = [];
      this.activeKey = [];
      // 这么麻烦是为了取消双向绑定
      let date = new Date(this.righttime);
      let Y = date.getFullYear() + "-";
      let M = date.getMonth() + 1 + "-";
      let D = date.getDate() + " ";
      // let h = date.getHours() + ":";
      // let m = date.getMinutes() + ":";
      // let s = date.getSeconds();
      //  h + m + s
      // 补零操作
      M = M.padStart(3, "0");
      D = D.padStart(3, "0");
      let time = Y + M + D;
      let time1 = new Date(time);
      // console.log(time, time1);
      // 加一天从今天开始
      time1.setDate(date.getDate() + 1);
      this.selNewDate(time1, this.days);
    },
    //  重置
    reset() {
      this.dateList = [];
      this.selKey = [];
      this.activeKey = [];
      this.selectedRowKey = [];
      this.userId = null;
      this.name = null;
      let date = new Date();
      // 加一天，从今天开始
      date.setDate(date.getDate() + 1);
      this.selNewDate(date, 7);
      this.righttime = null;
      this.ifdate = false;
    },
    // 选择日期
    onChange(date, dateString) {
      console.log(date, dateString);
      if (date.length != 0) {
        var lefttime = date[0]._d;
        var righttime = date[1]._d;
        let nbr = righttime - lefttime;
        var days = parseInt(nbr / (1000 * 60 * 60 * 24));
        // var hours = parseInt((nbr % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        // var minutes = parseInt((nbr % (1000 * 60 * 60)) / (1000 * 60));
        // var seconds = (nbr % (1000 * 60)) / 1000;
        // console.log(
        //   days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 "
        // );
        // righttime.setDate(righttime.getDate() + 1); //num正数为后一天，负数为前一天
        this.righttime = righttime;
        // console.log("我选择的时间是" + righttime);
        this.days = days;
        this.ifdate = false;
      } else {
        // 加一天，从今天开始
        let date = new Date();
        this.righttime = date;
        this.days = 7;
        this.ifdate = true;
      }
    },
    // 选择时间
    selDate(e) {
      this.activeKey = e;
      // console.log(e[e.length - 1]);
      for (let index = 0; index < this.dateList.length; index++) {
        if (e[e.length - 1] == this.dateList[index].name) {
          this.getdataList(e[e.length - 1], index);
          break;
        }
      }
    },
    // 获取数据
    getdataList(e, r) {
      this.$ajax({
        url: "/hxclass-management/operation-log",
        method: "get",
        params: {
          id: this.userId,
          time: e,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          let data = this.dateList[r];
          data.children = res.data;
          this.$set(this.dateList, r, data);
        }

        // console.log(this.dateList[r].children,r);
      });
    },
    // 添加数组
    selNewDate(num, r) {
      let long = false;
      const date = num; //当前时间
      for (let index = 0; index <= r; index++) {
        date.setDate(date.getDate() - 1); //num正数为后一天，负数为前一天
        let m = date.getMonth() + 1,
          mm = "-" + (m < 10 ? "0" + m : m);
        let d = date.getDate(),
          dd = "-" + (d < 10 ? "0" + d : d);
        let strdate = date.getFullYear() + mm + dd;
        this.dateList[index] = {
          name: strdate,
          children: [{}],
        };
      }
      // let index = -1;
      // do {
      //   index++;
      //   let m = date.getMonth() + 1,
      //     mm = "-" + (m < 10 ? "0" + m : m);
      //   let d = date.getDate(),
      //     dd = "-" + (d < 10 ? "0" + d : d);
      //   let strdate = date.getFullYear() + mm + dd;
      //   this.dateList[index] = {
      //     name: strdate,
      //     children: [{}],
      //   };
      //   date.setDate(date.getDate() - 1); //num正数为后一天，负数为前一天
      // } while (index <= r);
      this.strdate = date;
      // return date;
    },

    fetchData() {
      let long = false;
      var date = this.strdate; //获取当前存储时间
      date.setDate(date.getDate() - 1); //num正数为后一天，负数为前一天
      let m = date.getMonth() + 1,
        mm = "-" + (m < 10 ? "0" + m : m);
      let d = date.getDate(),
        dd = "-" + (d < 10 ? "0" + d : d);
      // let h = date.getHours(),
      //   hh = " " + (h < 10 ? "0" + h : h);
      // let i = date.getMinutes(),
      //   ii = ":" + (i < 10 ? "0" + i : i);
      // let s = date.getSeconds(),
      //   ss = ":" + (s < 10 ? "0" + s : s);
      let strdate = date.getFullYear() + mm + dd;
      this.strdate = date;

      this.dateList.push({
        name: strdate,
        children: [
          {
            title: "Child Node1",
          },
        ],
      });
    },
    // 监听到底部
    handleInfiniteOnLoad() {
      // 加一天，从今天开始
      let date = new Date();
      date.setDate(date.getDate() + 1);
      // console.log(this.righttime == date);
      // this.ifdate为true的时候是限制时间区间的
      if (this.ifdate) {
        this.fetchData();
      } else {
        this.$message.warning("数据就这些了。");
      }
    },

    //商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling(1);
    },
    // 表格操作
    selectKeys(selectedRowKey, e) {
      if (selectedRowKey.length === 0) {
        this.selectedRowKey = [];
        this.userId = null;
        this.name = null;
      } else {
        this.selectedRowKey = [];
        this.selectedRowKey.push(selectedRowKey[selectedRowKey.length - 1]);
        this.userId = e[0].userId;
        this.name = e[0].name;
      }
      // console.log(this.userId, this.name, e);
    },
    relationOk(e) {
      this.relation = false;
    },
    relationCanael() {
      this.relation = false;
    },
    relationFilling() {
      this.relation = true;
    },
    // 获取数据
    categoryFilling() {
      this.$ajax({
        url: "/hxclass-management/user/info/manage/list",
        params: {
          name: this.categoryname,
          pageNum: this.cpageNumber,
          pageSize: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // this.userinfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(this.userinfo);
    let date = new Date();
    // 加一天，从今天开始
    date.setDate(date.getDate() + 1);
    this.selNewDate(date, 7);
    this.categoryFilling();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.btn {
  margin-right: 24px;
}
.box_con {
  margin-top: 30px;
  .con_item {
    &:nth-last-child(1) {
      .main_item:nth-last-child(1) {
        &::after {
          position: absolute;
          bottom: 0px;
          left: -6px;
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 15px;
          background-color: #2b6ed4;
        }
      }
    }
    .con_date {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      font-weight: bold;
      background-color: #f6f6fc;
      padding: 12px 24px;
    }
    .con_main {
      padding: 10px 10px 0 0;
      margin-top: 10px;
      .main_item {
        position: relative;
        border-left: 1px solid #2b6ed4;
        padding: 0 10px 20px 10px;
        margin-left: 10px;
        &::before {
          position: absolute;
          top: 0px;
          left: -6px;
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-radius: 11px;
          background-color: #2b6ed4;
        }
        .item_title {
          color: #333333;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          .title_date {
            font-weight: 400;
          }
          .title_info {
            margin-left: 11px;
            font-weight: 500;
            color: #333333;
          }
        }
        .item_con {
          margin-top: 12px;
          font-size: 12px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #979797;
        }
      }
    }
  }
}
.search-box {
  margin-top: 40px;
  display: flex;
  margin-bottom: 10px;
  .search-input {
    width: 62%;
    margin-right: 24px;
    margin-bottom: 6px;
  }
}

.title1 {
  font-size: 12px;
  font-family: PingFang SC-Regular;
  font-weight: 400;
  color: #979797;
    margin-left: 15px;
  line-height: 14px;
}
</style>
